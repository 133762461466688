<template>
  <b-table
    :data="isEmpty ? [] : jobs"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-rounded="isPaginationRounded"
    :checkable="False"
    :checked-rows.sync="checkedRows"
    :checkbox-position="checkboxPosition"
    :checkbox-type="checkboxType"
  >
    <b-table-column
      field="title"
      label="Job Title"
      width="200"
      sortable
      v-slot="job"
    >
      <template>
        <router-link
          class="is-ghost"
          :to="{ name: 'JobDetail', params: { id: job.row.id } }"
        >
          {{ job.row.title }}
        </router-link>
      </template>
    </b-table-column>
    <b-table-column
      field="category"
      label="Category"
      width="150"
      sortable
      v-slot="job"
    >
      <template>
        {{ job.row.category.name }}
      </template>
    </b-table-column>
    <b-table-column
      field="location"
      label="Location"
      width="100"
      sortable
      v-slot="job"
    >
      <template>
        {{ job.row.location.name }}
      </template>
    </b-table-column>
    <b-table-column
      field="published-date"
      label="Published on"
      width="100"
      sortable
      v-slot="job"
    >
      <template v-if="job.row.status == '1'">
        <span class="tag is-success">
          {{ formatDate(job.row.published_on) }}
        </span>
      </template>
      <template v-else-if="job.row.status == '2'">
        <span class="tag is-warning">Draft </span>
      </template>
      <template v-else-if="job.row.status == '3'">
        <span class="tag is-info"> In review</span>
      </template>

      <template v-else-if="job.row.status == '4'">
        <span class="tag is-danger"> Rejected</span></template
      >
      <template v-else>
        <span class="tag is-warning">Not published</span>
      </template>
    </b-table-column>
    <b-table-column
      field="views"
      label="Impressions"
      width="75"
      sortable
      v-slot="job"
    >
      <template>
        {{ job.row.job_impressions }}
      </template>
    </b-table-column>
    <b-table-column
      field="views"
      label="Opens"
      width="75"
      sortable
      v-slot="job"
    >
      <template>
        {{ job.row.job_views }}
      </template>
    </b-table-column>
    <template #bottom-left>
      <b>Total checked</b>: {{ checkedRows.length }}
    </template>
    <template #empty>
      <div class="has-text-centered">We have nothing to show here.</div>
    </template>
  </b-table>
</template>

<script>
export default {
  //component information
  name: "JobTable",
  components: {},
  props: {
    jobs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEmpty: false,
      isPaginated: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      currentPage: 1,
      perPage: 10,
      checkboxPosition: "left",
      checkboxType: "is-primary",
      checkedRows: [],
    };
  },
  methods: {
    //format date to day/month/year
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
  },
};
</script>
