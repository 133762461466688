<template>
  <div class="jobs">
    <section>
      <b-field grouped group-multiline position="is-right" class="p-2">
        <!-- <b-tooltip label="Publish all">
          <button class="button is-light mb-2 mr-2" :loading="isLoading">
            <b-icon icon="rocket-launch" size="is-small"> </b-icon>
          </button>
        </b-tooltip>
        <b-tooltip label="Force close all">
          <button class="button is-light mb-2 mr-2" :loading="isLoading">
            <b-icon icon="alert-circle" size="is-small"> </b-icon>
          </button>
        </b-tooltip>
        <b-tooltip label="Delete all">
          <button class="button is-light mb-2 mr-2" :loading="isLoading">
            <b-icon icon="delete" size="is-small"> </b-icon>
          </button>
        </b-tooltip> -->
        <b-tooltip label="Refresh">
          <button
            class="button is-light mb-2"
            :loading="isLoading"
            @click="fetchJobs"
          >
            <b-icon icon="refresh" size="is-small"> </b-icon>
          </button>
        </b-tooltip>
      </b-field>

      <b-tabs :animated="false" :destroy-on-hide="true">
        <!-- <b-tab-item :label="'Active (' + getActiveJobs.length + ')'">
          <JobTable :jobs="getActiveJobs" />
        </b-tab-item>
        <b-tab-item :label="'Draft (' + getDraftJobs.length + ')'">
          <JobTable :jobs="this.getDraftJobs" />
        </b-tab-item> -->
        <b-tab-item :label="'Current (' + getCurrentJobs.length + ')'">
          <JobTable :jobs="this.getCurrentJobs" />
        </b-tab-item>
        <b-tab-item :label="'Closed (' + getClosedJobs.length + ')'">
          <JobTable :jobs="this.getClosedJobs" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import JobTable from "../../components/ui/JobTable.vue";

export default {
  components: {
    JobTable,
  },
  data() {
    const data = [
      {
        id: 1,
        title: "Active",
        jobs: [],
      },
      {
        id: 2,
        title: "Draft",
        jobs: [],
      },
      {
        id: 3,
        title: "Closed",
        jobs: [],
      },
    ];

    return {
      data,
      isLoading: false,
    };
  },
  computed: {
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
    allEmployers() {
      return this.$store.getters["employers/getEmployers"];
    },
    getJobs() {
      return this.$store.getters["jobs/getActiveJobs"];
    },
    getActiveJobs() {
      return this.$store.getters["jobs/getActiveJobs"];
    },
    getDraftJobs() {
      return this.$store.getters["jobs/getDraftJobs"];
    },
    getCurrentJobs() {
      return this.$store.getters["jobs/getCurrentJobs"];
    },
    getClosedJobs() {
      return this.$store.getters["jobs/getClosedJobs"];
    },
  },
  methods: {
    jobDetail(id) {
      this.$router.push({ name: "jobDetail", params: { slug: id } });
    },
    fetchJobs() {
      let emp = this.$store.getters["employers/getEmployer"];
      this.$store.dispatch("jobs/fetchJobs", emp.id);
    },
  },
};
</script>

<style scoped>
.red {
  border: 2px solid red;
}

.is-ghost:hover,
.is-ghost.is-hovered {
  color: #485fc7;
  text-decoration: underline;
}
</style>
